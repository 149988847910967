import { useMutation } from '@tanstack/react-query'
import Link from 'components/link.legacy'
import * as D from 'modules/document'
import * as Flash from 'modules/flash'
import { useNavigate, useSearch } from 'modules/router'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { useCallback, useMemo, useRef, useState } from 'react'
import User from 'resources/user'
import EmailCodeAuthForm from './email-code-auth-form'
import LoginForm from './login-form'
import Message from './message'
import TwoFactorAuthForm from './two-factor-auth-form'

const SignInForm = () => {
  const {
    auth_token: authTokenParam,
    email: initialEmail,
    referral: referralParam,
    user_id: userIdParam,
  } = useSearch()
  const formState = useRef({
    authToken: authTokenParam,
    email: initialEmail,
    password: undefined,
    rememberMe: false,
    referral: referralParam,
    userId: userIdParam,
  })
  const [firstName, setFirstName] = useState()
  const [showTwoFactorAuthForm, setShowTwoFactorAuthForm] = useState(() => {
    const params = new URLSearchParams(window.location.search)
    return !!params.get('auth_token')
  })
  const [showEmailCodeAuthForm, setShowEmailCodeAuthForm] = useState(false)

  const welcomeMessage = useMemo(() => _.sample(welcomeMessages), [])

  const navigate = useNavigate()

  const flashMessages = Flash.useMessages()
  const { clear } = Flash.useClear()
  D.useKeyDown(() => clear('error', 'warning'), { when: _.isPresent(flashMessages) })

  const onError = useCallback(
    ({ cause: { request, response } }) => {
      const options = { dismissible: false, timeout: 10000 }
      const { status } = response

      if (status === 406) {
        // eslint-disable-next-line react-compiler/react-compiler
        return (window.location.href = '/')
      }

      if (status >= 500) {
        return Flash.error(
          <>
            Oops, something went wrong during login! This isn’t your fault, and our team is looking
            into it right now.
            <br />
            If you have any questions, though, contact our friendly support team at{' '}
            <Link css={{ fontWeight: 'bold' }} href="mailto:support@cliniko.com">
              support@cliniko.com
            </Link>
            !
          </>,
          options
        )
      }

      const { locked, otp, valid } = response.body

      if (locked)
        return navigate({ to: '/users/unlock/new', search: { email: request.body?.user?.email } })
      if (!valid) {
        return Flash.error(
          <>
            This email and/or password is not correct. Do you want to{' '}
            <Link css={{ fontWeight: 'bold' }} href={resetPasswordLink(formState.current.email)}>
              reset your password?
            </Link>
          </>,
          options
        )
      }

      if (otp.required) {
        if (otp.provided) {
          Flash.error('This verification code is not valid. Please try again.', options)
        } else {
          if (otp.type === 'authenticator') {
            setShowTwoFactorAuthForm(true)
          } else {
            setShowEmailCodeAuthForm(true)
          }
        }
      }
    },
    [navigate, setShowTwoFactorAuthForm, setShowEmailCodeAuthForm]
  )

  const onSuccess = ({ response: { body, headers } }) => {
    const firstName = body?.user?.firstName ? _.trim(body.user.firstName) : ''
    setFirstName(firstName)
    window.location.href = headers.location
  }

  const signInRequest = useMutation(User.signInOptions({ bodyOnly: false, onError, onSuccess }))

  return _.cond(
    [R.not(R.isNil(firstName)), <Message>Welcome {firstName}!</Message>],
    [
      showTwoFactorAuthForm,
      <TwoFactorAuthForm
        disabled={signInRequest.isSuccess}
        onSubmit={signInRequest}
        referralParam={referralParam}
        state={formState}
      />,
    ],
    [
      showEmailCodeAuthForm,
      <EmailCodeAuthForm
        disabled={signInRequest.isSuccess}
        onSubmit={signInRequest}
        referralParam={referralParam}
        state={formState}
      />,
    ],
    [
      <LoginForm
        disabled={signInRequest.isSuccess}
        initialEmail={initialEmail}
        onSubmit={signInRequest}
        referralParam={referralParam}
        state={formState}
        welcomeMessage={welcomeMessage}
      />,
    ]
  )
}

const resetPasswordLink = email =>
  email ? `/users/password/new?email=${email}` : '/users/password/new'

const welcomeMessages = [
  'Welcome back! Nice to see you again.',
  'Thank you so much for using Cliniko!',
  '2% of all Cliniko subscriptions are donated to charity.',
  'Always stretch before using Cliniko.',
  'Get ready to book some appointments!',
  'Log in. Use Cliniko. Be happy.',
  'Hi there! We hope your day is going well.',
  'Cliniko works anywhere, even at the beach!',
  'Welcome! It’s a beautiful day.',
  'Treat yourself to something nice today.',
  'Have a great day!',
  'Press ? on the calendar to see the keyboard shortcuts.',
]

export default SignInForm
