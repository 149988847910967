import { tz } from '@date-fns/tz'
import {
  add,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  endOfDay,
  endOfMonth as endOfMonthFn,
  endOfWeek as endOfWeekFn,
  formatDistanceToNow,
  format as formatFn,
  isBefore as isBeforeDate,
  isDate,
  isFuture,
  isPast,
  isSameDay as isSameDayFn,
  parseISO,
  startOfDay,
  startOfMonth as startOfMonthFn,
  startOfWeek as startOfWeekFn,
  subDays,
  subMonths,
} from 'date-fns'
import { enAU } from 'date-fns/locale/en-AU'
import { enUS } from 'date-fns/locale/en-US'
import { pluralize } from 'modules/util/strings'
import * as R from 'ramda'

export { add, startOfDay, endOfDay }

export const formats = {
  date: 'd MMM yyyy',
  dateWithDay: 'E d MMM yyyy',
  iso: "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  jsDate: 'yyyy-MM-dd',
  time: 'h:mmaaa',
  timeWithTimeZone: 'h:mmaaa (z)',
}

export const daysAgo = days => subDays(endOfToday(), days)

export const daysFromNow = days => days && add(now(), { days })

export const daysFromNowToString = date => {
  if (!date) return ''

  const hoursLeft = differenceInHours(parse(date), now())

  if (hoursLeft <= 0) return '0 days'
  if (hoursLeft <= 12) return 'less than a day'

  const daysLeft = Math.round(hoursLeft / 24)
  return `${daysLeft} ${pluralize('day', daysLeft)}`
}

export const diffDays = R.curry((date, referenceDate) =>
  differenceInDays(parse(date), parse(referenceDate))
)

export const diffMonths = R.curry((date, referenceDate) =>
  differenceInMonths(parse(date), parse(referenceDate))
)

export const diffSeconds = (date, referenceDate) =>
  differenceInSeconds(parse(date), parse(referenceDate))

export const diffYears = R.curry((date, referenceDate) =>
  differenceInYears(parse(date), parse(referenceDate))
)

export const endOfMonth = (date = now()) => endOfMonthFn(date)

export const endOfToday = () => endOfDay(now())

export const endOfTomorrow = () => endOfDay(add(now(), { days: 1 }))

export const endOfWeek = (date = now(), options = { weekStartsOn: 1 }) => endOfWeekFn(date, options)

const getLocaleData = () =>
  ['en-BZ', 'en-FM', 'en-PW', 'en-US'].indexOf(window.locale) !== -1 ? enUS : enAU

export const formatDate = (date, { format: formatIn = formats.date } = {}) =>
  date && formatFn(date, formatIn, { locale: getLocaleData() })

export const formatDateOfBirth = date => date && formatDate(parseInUTC(date), { format: 'P' })

export const formatDateTime = (date, { dateFormat, timeFormat } = {}) =>
  date && `${formatDate(date, { format: dateFormat })}, ${formatTime(date, { format: timeFormat })}`

export const formatISO = date => date && formatDate(parseInUTC(date), { format: formats.iso })

export const formatTime = (date, { format: formatIn = formats.time } = {}) =>
  date && formatDate(date, { format: formatIn })

export const formatRelative = date => {
  if (!date) return

  const daysDifference = differenceInCalendarDays(now(), parse(date))

  if (daysDifference === 0) return `today, ${formatTime(date)}`
  if (daysDifference === 1) return `yesterday, ${formatTime(date)}`
  if (daysDifference === -1) return `tomorrow, ${formatTime(date)}`

  return formatDateTime(date)
}

export const isBefore = R.curry((date, referenceDate) =>
  isBeforeDate(parse(date), parse(referenceDate))
)

export const isInThePast = date => !!date && isPast(parse(date))

export const isInTheFuture = date => !!date && isFuture(parse(date))

export const isSameDay = (date1, date2) =>
  !!date1 && !!date2 && isSameDayFn(parse(date1), parse(date2))

export const monthsAgo = months => subMonths(endOfToday(), months)

export const now = () => parseISO(new Date().toISOString(), { in: tz(window.timeZone) })

export const parse = date =>
  date && parseISO(isDate(date) ? date.toISOString() : date, { in: tz(window.timeZone) })

const parseInUTC = date =>
  date && parseISO(isDate(date) ? date.toISOString() : date, { in: tz('UTC') })

export const startOfMonth = (date = now()) => startOfMonthFn(date)

export const startOfToday = () => startOfDay(now())

export const startOfTomorrow = () => startOfDay(add(now(), { days: 1 }))

export const startOfWeek = (date = now(), options = { weekStartsOn: 1 }) =>
  startOfWeekFn(date, options)

export const timeFromNowToString = (time, { includeSuffix: addSuffix } = {}) =>
  time ? formatDistanceToNow(parse(time), { addSuffix }) : ''
