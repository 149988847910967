import { useMutation } from '@tanstack/react-query'
import * as Flash from 'modules/flash'
import * as F from 'modules/form'
import { useNavigate, useSearch } from 'modules/router'
import User from 'resources/user'
import { inputStyles } from './input-styles'
import Message from './message'
import SubmitAndCancel from './submit-and-cancel'
import Transition from './transition'

const RequestPasswordReset = () => {
  const { email: initialEmail } = useSearch()

  const navigate = useNavigate()

  const onError = () => Flash.error('We couldn’t find this email address')

  const onSuccess = (_data, { body: { user } }) => {
    Flash.info('Check your email for the next step in getting your password reset.', {
      dismissible: false,
      timeout: 10000,
    })

    navigate({ to: '/users/sign_in', search: { email: user?.email } })
  }

  const passwordResetRequest = useMutation(User.resetPasswordOptions({ onError, onSuccess }))

  return (
    <F.Form aria-label="Reset password form" onSubmit={passwordResetRequest}>
      <Message>Forgot your password? No problem. Reset it here.</Message>
      <F.HiddenField defaultValue={window.account_id} name="user.account_id" />
      <Transition>
        <F.EmailInput
          autoFocus
          css={inputStyles}
          defaultValue={initialEmail}
          legacyStyle
          name="user.email"
          required
        />
      </Transition>
      <Transition delay={100}>
        <SubmitAndCancel submitText="Reset password" />
      </Transition>
    </F.Form>
  )
}

export default RequestPasswordReset
