import Button from 'components/button.legacy'
import { useState } from 'react'
import { usePasskey } from './use-passkey'

const PasskeyAuthButton = ({
  children = 'Log in with a passkey',
  isMediationEnabled = false,
  name = 'passkey_credential',
  onSubmit,
  state,
  setFormDisabled,
  twoFactor = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const resetForm = () => {
    setFormDisabled(false)
    setIsLoading(false)
  }

  const { fetchPasskey } = usePasskey({
    isMediationEnabled,
    onError: resetForm,
    onSubmit: ({ credential }) => {
      setFormDisabled(true)
      setIsLoading(true)

      const data = {
        auth_token: state.current.authToken,
        user_id: state.current.userId,
        user: {
          rememberMe: state.current.rememberMe,
          referral: state.current.referral,
          ...(twoFactor && {
            email: state.current.email,
            password: state.current.password,
            account_id: window.account_id,
          }),
          [name]: JSON.stringify(credential),
        },
      }

      return onSubmit.mutateAsync({ body: data }).finally(resetForm)
    },
  })

  return (
    <Button
      css={buttonStyles}
      icon={{ name: 'passkey', position: 'right', styles: { width: 18 } }}
      onClick={fetchPasskey}
      isLoading={isLoading}
      color="brand-light">
      {children}
    </Button>
  )
}

const buttonStyles = {
  width: '100%',

  '&[data-focus-visible-added]': {
    outlineColor: 'white',
  },
}

export default PasskeyAuthButton
