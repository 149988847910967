import * as S from 'apps/cliniko/style'
import * as F from 'modules/form'

const CheckboxAndSubmit = ({ state, ...props }) => (
  <div>
    <F.Checkbox
      css={checkBoxStyles}
      legacyStyle
      name="user.rememberMe"
      defaultChecked={state.current.rememberMe}
      onChange={event => {
        state.current.rememberMe = event.target.checked && event.target.value
      }}
      {...props}>
      Keep me logged in
    </F.Checkbox>
    <F.SubmitButton action legacyStyle css={submitButtonStyles}>
      Log in
    </F.SubmitButton>
  </div>
)

const submitButtonStyles = {
  width: '100%',
  borderColor: '#fff',

  '&[data-focus-visible-added]': {
    outlineColor: 'white',
  },
}

const checkBoxStyles = {
  marginBottom: S.rem(12),

  span: {
    color: 'white',
  },

  '.checkbox-input:checked + span:before': {
    borderColor: S.legacy.colors.primaryDarker,
  },

  '.checkbox-input:disabled + span': {
    color: 'white',
  },

  [S.legacy.media.belowMedium]: {
    marginBottom: S.rem(20),
  },
}

export default CheckboxAndSubmit
