import * as F from 'modules/form'
import * as R from 'ramda'

const Form = ({ children, referralParam, ...props }) => (
  <F.Form aria-label="Login form" {...props}>
    <F.HiddenField defaultValue={window.account_id} name="user.account_id" />
    {!R.isNil(referralParam) && <F.HiddenField defaultValue={referralParam} name="user.referral" />}
    {children}
  </F.Form>
)

export default Form
