import * as S from 'apps/cliniko/style'
import Button from 'components/button.legacy'
import * as F from 'modules/form'

const SubmitAndCancel = ({ submitText }) => (
  <div css={buttonContainer}>
    <F.SubmitButton action compact css={submitButtonStyles} legacyStyle>
      {submitText}
    </F.SubmitButton>
    <Button compact href="/users/sign_in" plain css={cancelButtonStyles}>
      Cancel
    </Button>
  </div>
)

const buttonContainer = {
  display: 'flex',
  flexDirection: 'column',

  [S.legacy.media.medium]: {
    flexDirection: 'row',
    gap: S.rem(8),
  },
}

const submitButtonStyles = [
  {
    borderColor: '#fff',

    '&[data-focus-visible-added]': {
      outlineColor: 'white',
    },

    [S.legacy.media.belowMedium]: {
      marginBottom: S.rem(20),
    },

    [S.legacy.media.medium]: {
      flex: 1,
    },
  },
]

const cancelButtonStyles = [
  {
    background: S.legacy.colors.primary,
    borderColor: S.legacy.colors.primaryDarker,
    color: 'white',

    '&[data-focus-visible-added]': {
      outlineColor: 'white',
    },

    '&:hover': {
      background: S.legacy.colors.primaryDark,
    },

    [S.legacy.media.medium]: {
      flex: 1,
    },
  },
]

export default SubmitAndCancel
