import { notify as notifyHoneybadger } from 'apps/error-reporting'
import Cookies from 'cookies-js'

const COOKIE_KEY = 'au1-migration-id'
const LEGACY_STORAGE_PARAM = 'store'
const STORAGE_PARAM = 'store_utf8'
const ID_PARAM = 'id'

const subdomain = () => window.location.host.split('.')[0].toLowerCase()

export const decodeBase64 = base64 =>
  new TextDecoder().decode(Uint8Array.from(atob(base64), m => m.codePointAt(0)))

export const encodeBase64 = str =>
  btoa(Array.from(new TextEncoder().encode(str), byte => String.fromCodePoint(byte)).join(''))

const loadLocalstorage = () => {
  try {
    const url = new URL(window.location.href)
    if (!url.hash) {
      return
    }

    const params = new URLSearchParams(url.hash.replace(/^#/, ''))
    let paramValue = params.get(STORAGE_PARAM)

    if (!paramValue) {
      if (params.has(LEGACY_STORAGE_PARAM)) {
        // Upgrade previously encoded latin-only text to utf8 compatible format
        paramValue = encodeBase64(atob(params.get(LEGACY_STORAGE_PARAM)))
      }
      if (!paramValue) {
        return
      }
    }

    const storageId = params.get(ID_PARAM)
    const cookieValue = Cookies.get(COOKIE_KEY)

    if (cookieValue !== storageId) {
      console.error('Key could not be authenticated')

      params.delete(STORAGE_PARAM)
      params.delete(LEGACY_STORAGE_PARAM)
      params.delete(ID_PARAM)
      if (params.size > 0) {
        url.hash = `#${params.toString()}`
      } else {
        url.hash = ''
      }

      window.history.replaceState(null, document.title, url.toString())
      return
    }

    const storeData = JSON.parse(decodeBase64(paramValue))

    Object.entries(storeData).forEach(([key, value]) => {
      window.localStorage.setItem(key, value)
    })

    Cookies.expire(COOKIE_KEY)
    Cookies.set(`au1-migrated-${subdomain()}`, '1', { expires: Infinity, domain: 'cliniko.com' })

    url.hash = ''
    window.history.replaceState(null, document.title, url.toString())
  } catch (e) {
    notifyHoneybadger(e)
  }
}

const migrateLocalstorage = () => {
  try {
    const url = new URL(window.location.href)
    url.host = url.host.replace(/(^|\.)cliniko\.com$/, '$1au1.cliniko.com')

    if (!Cookies.get(`au1-migrated-${subdomain()}`)) {
      const hash = new URLSearchParams()
      hash.set(STORAGE_PARAM, encodeBase64(JSON.stringify(window.localStorage || {})))

      const storageId = crypto.randomUUID()
      Cookies.set(COOKIE_KEY, storageId, { domain: 'cliniko.com', expires: 60, secure: true })
      hash.set(ID_PARAM, storageId)

      url.hash = `#${hash.toString()}`
    }
    window.location.href = url.toString()
  } catch (e) {
    notifyHoneybadger(e)
  }
}

export const migrate = () => {
  if (
    !(window.environment === 'production' && window.CLINIKO_SHARD === 'au1' && window.au1Migration)
  ) {
    return
  }
  if (/^[^.]+\.au1\.cliniko\.com$/.test(window.location.host)) {
    loadLocalstorage()
  } else if (/^[^.]+\.cliniko\.com$/.test(window.location.host)) {
    migrateLocalstorage()
  }
}
