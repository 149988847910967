import * as S from 'apps/cliniko/style'
import Heading from 'components/heading.legacy'
import Link from 'components/link.legacy'
import Prose from 'components/prose.legacy'
import * as F from 'modules/form'
import * as _ from 'modules/util'
import * as W from 'modules/window'
import { useState } from 'react'
import CheckboxAndSubmit from './checkbox-and-submit'
import Form from './form'
import { inputStyles } from './input-styles'
import PasskeyAuthButton from './passkey-auth-button'
import Transition from './transition'

const LoginForm = ({ disabled: disabledIn, initialEmail, onSubmit, referralParam, state }) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <Form disabled={disabled || disabledIn} onSubmit={onSubmit} referralParam={referralParam}>
      <Transition>
        <Heading level={2} css={headingStyles}>
          Log in to <strong>{window.account_name}</strong>.
        </Heading>

        <F.EmailInput
          autoComplete="username webauthn"
          autoFocus={!W.hasTouch && _.isBlank(initialEmail)}
          css={[inputStyles, S.legacy.space.mb(2)]}
          defaultValue={initialEmail}
          label={{ text: 'Email address', css: { color: 'white' } }}
          legacyStyle
          name="user.email"
          // eslint-disable-next-line react-compiler/react-compiler
          onChange={event => (state.current.email = event.target.value)}
          required
        />
      </Transition>
      <Transition delay={100}>
        <div css={{ position: 'relative' }}>
          <F.PasswordInput
            autoComplete="password webauthn"
            autoFocus={!W.hasTouch && _.isPresent(initialEmail)}
            css={inputStyles}
            legacyStyle
            name="user.password"
            onChange={event => (state.current.password = event.target.value)}
            required
          />
          <F.Data watch="user.email">
            {data => (
              <Link
                css={resetPasswordLinkStyles}
                href={resetPasswordLink(data?.user?.email || initialEmail)}>
                Forgot password?
              </Link>
            )}
          </F.Data>
        </div>
      </Transition>
      <Transition delay={200}>
        <CheckboxAndSubmit state={state} />
        <div css={dividerStyles}>
          <span css={S.legacy.space.px(1)}>or</span>
        </div>
        <PasskeyAuthButton
          isMediationEnabled={true}
          onSubmit={onSubmit}
          setFormDisabled={setDisabled}
          state={state}
        />
        <Prose css={[S.legacy.space.mt(2), { textAlign: 'center' }]}>
          <Link
            css={passkeyHelpLinkStyles}
            href="https://help.cliniko.com/en/articles/10287538-using-passkeys-to-log-into-cliniko-an-overview">
            Learn how to add a passkey.
          </Link>
        </Prose>
      </Transition>
    </Form>
  )
}

const resetPasswordLink = email =>
  email ? `/users/password/new?email=${email}` : '/users/password/new'

const headingStyles = {
  color: '#fff',
  fontWeight: 'normal',
  textAlign: 'center',
  ...S.legacy.space.mb(4),
}

const dividerStyles = {
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  ...S.legacy.space.my(3),

  '&::before, &::after': {
    content: '""',
    flex: 1,
    height: '1px',
    backgroundColor: '#ffffff88',
    position: 'relative',
    bottom: '-1px',
  },
}

const resetPasswordLinkStyles = {
  boxSizing: 'content-box',
  color: 'white !important',
  lineHeight: S.rem(24),
  position: 'absolute',
  zIndex: 1,
  top: 0,
  right: 0,

  '&[data-focus-visible-added]': {
    outlineColor: 'white',
  },
}

const passkeyHelpLinkStyles = {
  color: 'white',
  '&[data-focus-visible-added]': {
    outlineColor: 'white',
  },
}

export default LoginForm
