import { useMutation } from '@tanstack/react-query'
import * as Flash from 'modules/flash'
import * as F from 'modules/form'
import { useNavigate, useSearch } from 'modules/router'
import User from 'resources/user'
import { inputStyles } from './input-styles'
import Message from './message'
import SubmitAndCancel from './submit-and-cancel'
import Transition from './transition'

const ConfirmUser = () => {
  const { email: initialEmail } = useSearch()

  const navigate = useNavigate()

  const onError = ({ cause: { response } }) => Flash.error(response.body, { timeout: 10000 })

  const onSuccess = (_data, { body: { user } }) => {
    Flash.info('Check your email for the next step in confirming your address change.', {
      dismissible: false,
      timeout: 10000,
    })

    navigate({ to: '/users/sign_in', search: { email: user?.email } })
  }

  const confirmUserRequest = useMutation(User.confirmOptions({ onError, onSuccess }))

  return (
    <F.Form aria-label="Confirm user form" onSubmit={confirmUserRequest}>
      <Message>
        Didn’t get your confirmation email? Enter your new address here to resend it.
      </Message>
      <F.HiddenField defaultValue={window.account_id} name="user.account_id" />
      <Transition>
        <F.EmailInput
          autoFocus
          css={inputStyles}
          defaultValue={initialEmail}
          legacyStyle
          name="user.email"
          required
        />
      </Transition>
      <Transition delay={0.1}>
        <SubmitAndCancel submitText="Resend email" />
      </Transition>
    </F.Form>
  )
}

export default ConfirmUser
