import * as S from 'apps/cliniko/style'
import Icon from 'components/icon.legacy'
import Link from 'components/link.legacy'
import Loader from 'components/loader.legacy'
import * as _ from 'modules/util'

const Button = ({
  action,
  children,
  color: colorIn,
  compact,
  disabled: disabledIn,
  icon,
  isLoading = false,
  link,
  ...props
}) => {
  const color = action ? 'action' : colorIn
  const disabled = disabledIn || isLoading
  const iconRight = icon?.position === 'right'
  const style = styles({ color, compact, disabled: disabledIn, link })

  const childrenWithIcon = (
    <span
      css={childrenWithIconContainerStyles({ iconRight })}
      style={_.when(isLoading, { visibility: 'hidden' })}>
      {!!icon && (
        <Icon
          css={[
            iconStyles({
              action,
              color,
              hasSibling: _.isPresent(children),
              isLink: link,
              right: iconRight,
            }),
            icon?.styles,
          ]}
          name={icon?.name || icon}
        />
      )}
      {children}
    </span>
  )

  return props.href ? (
    <Link css={style} plain {...props}>
      {childrenWithIcon}
    </Link>
  ) : (
    <button css={style} disabled={disabled} type="button" {...props}>
      {!!isLoading && <Loader css={S.absoluteCenter} inverse={!action} />}
      {childrenWithIcon}
    </button>
  )
}

const buttonStyles = ({ color, compact, disabled }) => [
  S.legacy.buttonPadding({ compact }),
  {
    alignItems: 'center',
    display: 'inline-flex',
    borderRadius: S.borderRadius,
    boxShadow: _.unless(color === 'control', `0 ${S.rem(2)} rgba(75, 56, 76, 0.05)`),
    cursor: disabled ? 'not-allowed' : 'pointer',
    fontWeight: color === 'action' ? 600 : 400,
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    textShadow: _.when(color === 'action', `0 ${S.rem(1)} rgba(0, 0, 0, 0.2)`),
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(204, 48, 87, 0.8)',
    zIndex: 1,
    backgroundColor: _.cond(
      [color === 'action', S.legacy.colors.action],
      [color === 'brand', S.legacy.colors.primaryDarkShade],
      [color === 'brand-light', S.legacy.colors.primary],
      [color === 'dark', S.legacy.colors.secondaryDark],
      [color === 'confirm', S.legacy.colors.success],
      [color === 'danger', S.legacy.colors.error],
      [color === 'warning', S.legacy.colors.warning],
      [color === 'control', S.legacy.colors.secondaryMuchLighter],
      ['white']
    ),
    border: `1px solid ${_.cond(
      [color === 'action', S.legacy.colors.actionBorder],
      [color === 'brand', S.legacy.colors.primaryDarkShade],
      [color === 'brand-light', S.legacy.colors.secondaryLightest],
      [color === 'dark', S.legacy.colors.secondaryDarker],
      [color === 'confirm', S.legacy.colors.successDark],
      [color === 'danger', S.legacy.colors.errorBorder],
      [color === 'warning', S.legacy.colors.warningBorder],
      [color === 'control', S.legacy.colors.line],
      [S.legacy.colors.inputLine]
    )}`,
    color: ['action', 'brand', 'brand-light', 'danger', 'dark'].includes(color)
      ? 'white'
      : S.legacy.colors.bodyFont,

    '&:hover': {
      backgroundColor: _.cond(
        [color === 'action', S.legacy.colors.actionHover],
        [color === 'brand', S.legacy.colors.primaryDarker],
        [color === 'brand-light', S.legacy.colors.primaryDark],
        [color === 'dark', S.legacy.colors.secondaryDarker],
        [color === 'confirm', S.legacy.colors.successShade],
        [color === 'danger', S.legacy.colors.errorShade],
        [color === 'warning', S.legacy.colors.warningShade],
        [S.legacy.colors.secondaryLightest]
      ),
      textDecoration: 'none !important',
    },

    '&[data-focus-visible-added]': {
      outline: `1px dashed ${S.legacy.colors.action}`,
      outlineOffset: S.rem(2),
    },

    '&:active, &.is-active': {
      transform: 'translateY(1px)',
      boxShadow: 'none',
    },
  },
  _.when(disabled, disabledStyles({ color })),
]

const childrenWithIconContainerStyles = ({ iconRight }) => [
  {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: _.when(iconRight, 'row-reverse'),
  },
]

const iconStyles = ({ action, color, hasSibling, isLink, right }) => [
  {
    position: 'relative',
    width: S.rem(action ? 9 : 11),
    fill: _.cond(
      [isLink, S.legacy.colors.action],
      [['action', 'brand', 'dark'].includes(color), 'white'],
      [color === 'confirm', S.legacy.colors.successDark],
      [color === 'danger', S.legacy.colors.errorBorder],
      [color === 'warning', S.legacy.colors.warningSvg],
      [S.legacy.colors.primaryLighter]
    ),
  },
  _.cond([hasSibling && right, { marginLeft: S.rem(8) }], [hasSibling, { marginRight: S.rem(8) }]),
]

const linkStyles = {
  color: S.legacy.colors.action,
  background: 'transparent',
  textDecoration: 'underline',
  display: 'inline',
  padding: 0,

  '&:hover': {
    textDecoration: 'underline',
  },

  '&[data-focus-visible-added]': {
    outline: `1px dashed ${S.legacy.colors.action}`,
    outlineOffset: S.rem(2),
  },
}

const disabledStyles = ({ color }) => ({
  borderColor: _.unless(color === 'brand', S.legacy.colors.lineLight),
  background: _.unless(color === 'brand', S.legacy.colors.secondaryLightest),
  color: _.unless(color === 'brand', S.legacy.colors.secondaryLighter),
  opacity: _.when(color === 'brand', 0.5),
  pointerEvents: 'none',
  textShadow: 'none',
})

const styles = ({ color, compact, disabled, link }) =>
  link ? linkStyles : buttonStyles({ color, compact, disabled })

export default Button
